import React from "react";
import Layout from "../../../components/Layout";
import Podcast from "../../../components/Podcast/Podcast";

const PodcastPage = ({ location }) => {
  const lang = "ja";

  // Page setting
  const pageTitle = "屋根裏トーク";
  const pageSlug = "podcast";
  const pageKeywords = [`ポッドキャスト`, `屋根裏トーク`, `Podcast`];
  const pageDescription =
    "箕澤屋(みさわや)の屋根裏からはじまった管理人AyanaとKobadaiの雑談ポッドキャスト「屋根裏トーク」です。古民家での生活や日々の出来事など、長野とNYからゆるっとした会話をお届けします。";
  const pageImage = "";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <Podcast pageTitle={pageTitle} />
    </Layout>
  );
};

export default PodcastPage;
