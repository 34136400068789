import React from "react";
import { Link } from "gatsby";
import styles from "./Podcast.module.scss";
import podcastEpisodes from "../../utils/podcastEpisodes";

export default () => {
  return (
    <div className={styles.root}>
      <div className="content-area">
        <div className="page-title-wrapper">
          <h1 className="page-title container-sm">Podcast</h1>
        </div>

        <div className="container-sm">
          <div className={styles.lead}>
            <p>
              管理人AyanaとKobadaiの雑談ポッドキャスト「屋根裏トーク」です。古民家での生活や日々の出来事、食や健康、アウトドアなど、さまざまなテーマでゆるっと語り合います。ちょっとだけ耳が空いた時間ありましたら、流し聞きでもしていただけたらうれしいです。
            </p>
            <p>
              ちなみに、屋根裏トークという名前の由来は、箕澤屋の屋根裏に隠れて存在する、元女中部屋での雑談から始まったところからきています。
            </p>
          </div>
          <ul className={styles.subscribeList}>
            <li>
              <a
                href="https://podcasts.apple.com/us/podcast/%E5%B1%8B%E6%A0%B9%E8%A3%8F%E3%83%88%E3%83%BC%E3%82%AF/id1564532254"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apple
              </a>
            </li>
            <li>
              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy81Njk4OWM3NC9wb2RjYXN0L3Jzcw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                Google
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/show/4qJATzFaur1S6aqLK1DXh4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spotify
              </a>
            </li>
          </ul>
          <h2 className={styles.title}>今までのエピソード</h2>
          <ul className={styles.episodeList}>
            {(() => {
              return podcastEpisodes.map((ep, i) => {
                return (
                  <li key={i}>
                    <Link to={`/jp/podcast/${ep.id}/`}>
                      #{ep.id} {ep.title}
                    </Link>
                  </li>
                );
              });
            })()}
          </ul>
        </div>
      </div>
    </div>
  );
};
